import {
  SimulatorType,
  TitleTags,
} from '@pretto/website/src/templates/blocs/components/simulatorRow/SimulatorRow.types'
import { RowSimulator } from '@pretto/website/src/templates/blocs/rows/rowSimulator/components/rowSimulator/RowSimulator'
import { RawTabsData, useSentences } from '@pretto/website/src/templates/blocs/rows/rowSimulator/lib/useSentences'

export type Tab = { tablabel: string; ctalabel: string }

interface RowSimulatorContainerProps {
  isColored?: boolean
  isReversed?: boolean
  projectTypes: SimulatorType[]
  suptitle?: string
  projectTabs: RawTabsData
  text: TitleTags
  title: string
}

export const RowSimulatorContainer: React.FC<RowSimulatorContainerProps> = ({
  isColored,
  isReversed,
  projectTypes,
  suptitle,
  projectTabs,
  text,
  title,
}) => {
  const simulatorProps = useSentences({ tabs: projectTypes, tabsData: projectTabs })

  const props = {
    isColored,
    isReversed,
    simulatorProps,
    suptitle,
    text,
    title,
  }

  return <RowSimulator {...props} />
}
